import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../contexts/AppContext';
import { getTranslation } from '../common/Translation';
import { ReactComponent as PlayIconSvg } from "../assets/icon_play.svg";
import { ReactComponent as StopIconSvg } from "../assets/icon_stop.svg";
import BlockContainer from './BlockContainer';
import useFetchMedia from '../hooks/useFetchMedia';
import { MEDIA_PREFIX } from '../Settings';

interface BlockAudioWrapperProps {
  children?: JSX.Element | JSX.Element[]
}

interface BlockAudioItemProps {
  media?: any,
  layout?: "wide" | "block",
  children?: JSX.Element | JSX.Element[]
}

const Wrapper = styled.ul`
  list-style: none;
  float: left;
  margin: 0 0 20px;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  &:empty {
    display: none;
  }
`;

const AudioItem = styled.li`
  margin: 0;
  float: left;
  padding: 30px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #FFFFFF15;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.block {
    width: 150px;
    min-width: 150px;
    height: 150px;
    justify-content: center;
    position: relative;

    & button::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 70px;
    }

    & span, & div {
      display: none;
    }
  }
`;

const Caption = styled.span`
  --max-lines: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;


const Button = styled.button`
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-right: 20px;
  background-color: ${p => p.theme.accentColor};
  border: none;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: all;

  &:active {
    opacity: .8;
  }
`;

const AudioElement = styled.audio`
  display: none;
`;

const PlayIcon = styled(PlayIconSvg)`
  margin-left: 3px;
  height: 20px;
  width: 20px;
`

const StopIcon = styled(StopIconSvg)`
  height: 18px;
  width: 18px;
`

const Control = styled.div`
  width: 100%;
`;

const ProgressBar = styled.div`
  width: 100%;
  background-color: #FFFFFF15;
  height: 4px;
  border-radius: 2px;
  margin-top: 15px;
  position: relative;

  &::before {
    width: 100%;
    content: "";
    position: absolute;
    padding: 30px 0;
    transform: translateY(-50%);
  }
`;

const Progress = styled.div`
  width: 0;
  height: 4px;
  border-radius: 2px;
  background-color: ${p => p.theme.accentColor};
  transition: width .1s;
  pointer-events: none;
`;

/**
 * Render an audio block container
 * @returns {JSX.Element} Component template
 */
export const BlockAudioWrapper: FC<BlockAudioWrapperProps> = ({children}) => {
  return (
    <BlockContainer>
      <Wrapper>{children}</Wrapper>
    </BlockContainer>
  );
}



/**
 * Render an audio block item
 * @returns {JSX.Element} Component template
 */
export const BlockAudioItem: FC<BlockAudioItemProps> = ({media, layout, children}) => {
  const { currentLanguage, globalVolume, resetTimer } = useContext(AppContext);
  const [playbackPosition, setPlaybackPosition] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audioElement = useRef<HTMLAudioElement>(null);
  const [itemAudio] = useFetchMedia(media);

  /**
   * Stop all audio
   */
   const stopAllAudio = useCallback(() => {
    const audioElements = document.querySelectorAll("audio");
    audioElements.forEach(el => {
      el.pause();
      el.currentTime = 0;
    });
  }, []);

  /**
   * Toggle audio placback
   */
   const togglePlay = useCallback(() => {
    if (isPlaying) {
      if (audioElement.current) {
        audioElement.current.pause();
        audioElement.current.currentTime = 0;
      }
      setIsPlaying(false);
    } else {
      stopAllAudio();
      if (audioElement.current) {
        audioElement.current.play();
        audioElement.current.currentTime = 0;
      }
      setIsPlaying(true);
    }
  }, [isPlaying, stopAllAudio]);

  useEffect(() => {
    if (audioElement.current && globalVolume !== undefined) {
      audioElement.current.volume = globalVolume;
    }
  }, [globalVolume]);

  // Listen for audio player time change
  useEffect(() => {
    if (audioElement.current) {
      audioElement.current?.addEventListener("timeupdate", e => {
        setPlaybackPosition(Number((audioElement.current?.currentTime || 0) / (audioElement.current?.duration || 0)) * 100);
        resetTimer && resetTimer();
      })

      audioElement.current?.addEventListener("ended", e => {
        setIsPlaying(false);
        if (audioElement.current) {
          audioElement.current.currentTime = 0;
          resetTimer && resetTimer();
        }
      })

      audioElement.current?.addEventListener("pause", e => {
        setIsPlaying(false);
      })
    }
  }, [audioElement, resetTimer]);

  /**
   * Update playback position on click
   */
  const updatePosition = useCallback((e: any) => {
    if (isPlaying && audioElement.current) {
      const boundingRect = e.target.getBoundingClientRect();
      audioElement.current.currentTime = Number((((e?.clientX - boundingRect?.left)) / (e?.target?.offsetWidth)) * audioElement.current.duration);
    }
  }, [isPlaying]);

  return (
    <AudioItem className={`${layout || ""} showInPreview`}>
      <AudioElement ref={audioElement} src={itemAudio?.url ? `${MEDIA_PREFIX}${itemAudio?.url}` : undefined}/>
      <Button onClick={togglePlay}>{isPlaying ? (<StopIcon/>) : (<PlayIcon/>)}</Button>
      <Control>
        {itemAudio?.title && (<Caption>{getTranslation(itemAudio?.title, currentLanguage)}</Caption>)}
        <ProgressBar onClick={updatePosition}><Progress style={{width: `${playbackPosition}%`}}/></ProgressBar>
      </Control>
      {children}
    </AudioItem>
  );
}