import { FC, useCallback, useContext, useEffect } from 'react';
import styled from 'styled-components';
import BlockContainer from './BlockContainer';
import { AppContext } from '../contexts/AppContext';
import BlockError from './BlockError';
import { useTranslation } from 'react-i18next';

interface BlockWidgetProps {
  html: string
}

const Wrapper = styled.div`
  float: left;
  width: 100%;
  display: block;

  position: relative;
  z-index: 0;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;

  & iframe {
    border: none;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 50px);
    height: 100%;
  }
`;

/**
 * Render a widget block, used for embedding third party functionality. Strips all tags except of <iframe/>
 * @returns {JSX.Element} Component template
 */
const BlockWidget: FC<BlockWidgetProps> = ({html}) => {
  const { t } = useTranslation();
  const { previewIsActive, resetTimer } = useContext(AppContext);

  /**
   * Strip all tags except from <iframe/>
   * @param {string} html HTML to parse
   */
  const stripTags = useCallback((html: string) => {

    // Strip all tags except <iframe/> tag
    let cleanCode = html?.replace(/<(?!iframe\s*\/?)[^>]+>/g, "") || "";

    if (cleanCode.includes("sandbox")) {
      // Remove illegal sandbox values
      cleanCode = cleanCode.replace("allowfullscreen", "");
      cleanCode = cleanCode.replace("allow-modals", "");
      cleanCode = cleanCode.replace("allow-orientation-lock", "");
      cleanCode = cleanCode.replace("allow-pointer-lock", "");
      cleanCode = cleanCode.replace("allow-popups", "");
      cleanCode = cleanCode.replace("allow-popups-to-escape-sandbox", "");
      cleanCode = cleanCode.replace("allow-presentation", "");
      cleanCode = cleanCode.replace("allow-top-navigation", "");
      cleanCode = cleanCode.replace("allow-top-navigation-by-user-activation", "");
    } else {
      // Define sandbox if not already available
      cleanCode = cleanCode.replace("<iframe", "<iframe sandbox='allow-scripts allow-same-origin allow-presentation'");
    }

    return cleanCode;
  }, []);

  // Reset idle timer if clicked element is an iframe
  const clickCheck = useCallback((e) => {
    if (document.activeElement === document.querySelector("iframe")) {
      resetTimer();
    }
  }, [resetTimer]);

  // Detect blur event - for idle timer reset
  useEffect(() => {
    if (previewIsActive) { return }

    window.addEventListener('blur', (e) => {
      clickCheck(e);
      setTimeout(() => { window.focus(); }, 1000);
    });

    return () => window.removeEventListener('blur', clickCheck);
  }, [clickCheck, previewIsActive]);

  if (!html) { return null; }

  return (
    <BlockContainer>
      <>{previewIsActive && <BlockError text={t("block.iframeWarning")} includeWrapper={false}/> }</>
      <Wrapper dangerouslySetInnerHTML={{__html: stripTags(html)}}/>
    </BlockContainer>
  );
}

export default BlockWidget;