import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../contexts/AppContext';
import styled from 'styled-components';
import Header from '../framework/Header';
import PageTransition from '../components/PageTransition';
import { useLocation } from 'react-router-dom';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
  opacity: 0;
  background-color: #000;
  overflow: hidden;

  &.active {
    z-index: 10;
    opacity: 1;
  }
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

interface IframeProps {
  previewData?: any,
  overrideTitle?: string,
  active?: boolean
}

/**
 * Render the timeline view
 * @returns {JSX.Element} Component template
 */
const IframeView: FC<IframeProps> = ({previewData=null, overrideTitle, active}) => {
  const { resetTimer, previewIsActive } = useContext(AppContext);
  const { search } = useLocation();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [currentUrl, setCurrentUrl] = useState<string|null>(null);

  // Get page and tab from URL
  useEffect(() => {
    const params = new URLSearchParams(search);
    const url = params.get('url');
    url && setCurrentUrl(decodeURIComponent(url));
  }, [search]);

  // Reset idle timer if clicked element is an iframe
  const clickCheck = useCallback((e) => {
    if (document.activeElement === document.querySelector("iframe")) {
      resetTimer();
    }
  }, [resetTimer]);

  // Detect blur event - for idle timer reset
  useEffect(() => {
    setTimeout(() => {
      if (previewIsActive || !iframeRef?.current) { return }
  
      window.addEventListener('blur', (e) => {
        clickCheck(e);
        setTimeout(() => { window.focus(); }, 1000);
      });
  
    }, 1000);

    return () => window.removeEventListener('blur', clickCheck);
  }, [clickCheck, previewIsActive]);

  if (!currentUrl) { return null }

  return (
    <Wrapper className={active ? "active" : undefined}>
      <Header hideBackButton={true}/>
      <PageTransition>
        <Iframe ref={iframeRef} src={currentUrl} sandbox="allow-scripts allow-same-origin"/>
      </PageTransition>
    </Wrapper>
  );
}

export default IframeView;
